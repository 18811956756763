import React from 'react'
import {Link, graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import BlockContent from '../components/block-content'
import {imageUrlFor} from '../lib/image-url'
import {buildImageObj} from '../lib/helpers'
import styled from 'styled-components'
import {responsiveTitle1, responsiveTitle2} from '../components/typography.module.css'
import Fade from 'react-reveal/Fade'
import {FaLinkedin, FaResearchgate, FaFacebookSquare, FaTwitter, FaFileAlt} from 'react-icons/fa'
import {MdSchool} from 'react-icons/md'

export const query = graphql`
    query ProjectTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      teamExcerpt
      teamImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    }
    person: sanityPerson(id: {eq: $id}) {
      name
        tel
        location
        slug{
          current
        }
        _rawBio
        image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
        tags {
          _key
          _type
          name
          url
          platform
        }
    }
  }
`
const Image = styled.img`
  width: 100%;
  justify-self: center;
`
const StyledLink = styled(Link)`
:hover{
    display:block;
    margin-left: -5px;
  }
`
const Wrapper = styled.section`
  margin: 1em 0;
  border-top: 1px solid white;
  padding: 2em 0;
  display: grid;
  justify-content:center;
  grid-template-columns: 1fr 2fr ;
  grid-column-gap: 2em;
  h1{
    margin-top:0;
  }
  h2{
    padding-top: auto;
  }
  p{
    opacity:0.8;
  }
  strong{
        opacity:1;

  }
  ul{
    padding:0;
    display:flex;
  }
  li{
    padding-right:1rem;
    display: flex;
    align-items:center;
  }
  @media (max-width:600px){
  h1{
    margin-top:0;
  }
    grid-template-columns: 1fr ;
    grid-column-gap: 1fr auto;
  }
`

const getIconFromPlatform = (platform) => {
  switch (platform) {
    case 'LinkedIn':
      return <FaLinkedin size={28} />
    case 'Researchgate':
      return <FaResearchgate size={28} />
    case 'Twitter':
      return <FaFacebookSquare size={28} />
    case 'Facebook':
      return <FaTwitter size={28} />
    case 'GoogleScholar':
      return <MdSchool size={28} />
  }
}

const ProjectTemplate = props => {
  const {data, errors} = props
  const person = data && data.person
  const site = data.site

  console.log(person)
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {person && <SEO title={person.name || 'Untitled'} />}
      <Container>
        <Hero excerpt={site.teamExcerpt} title='Team' mainImage={site.teamImage} />
        <StyledLink to='/team/'>← Go Back</StyledLink>
        <Wrapper>
          <Fade>
            <div>
              <Image
                src={imageUrlFor(buildImageObj(person.image))
                  .width(600)
                  .height(600)
                  .fit('crop')
                  .url()}
                alt={person.image}
              />
              <ul>
                {person.tags[0] &&
                  person.tags.map(social => (
                    <li><a href={social.url} target='_blank'>{getIconFromPlatform(social.platform[0])}</a></li>
                  ))
                }
                {person.tel &&
                <li><a href={'tel:' + person.tel}>Tel: +{person.tel}</a></li>
                }
              </ul>
            </div>
          </Fade>
          <Fade>
            <div>
              <h1 className={responsiveTitle1}>{person.name}</h1>
              <BlockContent blocks={person._rawBio} />
            </div>
          </Fade>
        </Wrapper>
      </Container>

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
    </Layout>
  )
}

export default ProjectTemplate
